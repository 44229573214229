interface NavigationItem {
  label: string
  key: string
  trkey: string
  href: string
  subMenu?: SubMenuItem[]
}

interface SubMenuItem {
  label: string
  href: string
  key: string
  trkey?: string
}
const navigation: NavigationItem[] = [
  {
    label: 'Společnost',
    key: 'spolecnost',
    trkey: 'company',
    href: '/',
    subMenu: [
      { label: 'O nás', href: '/o-nas', key: 'about', trkey: 'about' },
      { label: 'Často kladené otázky', href: '/faq', key: 'faq', trkey: 'faq' },
    ],
  },
  {
    label: 'Kontakty',
    href: '/kontakt',
    key: 'contact',
    trkey: 'contact',
  },
]

const profileNavigation = [
  {
    label: 'Přehled',
    key: 'profile-overview',
    href: '/profile/overview',
    icon: 'i-fubex-square-menu',
    trkey: 'profileOverview',
  },
  {
    label: 'Směna',
    key: 'profile-exchange',
    href: '/profile/exchange',
    icon: 'i-fubex-exchange',
    trkey: 'profileExchange',
  },
  {
    label: 'Historie směn',
    key: 'profile-history',
    href: '/profile/history',
    icon: 'i-fubex-history',
    trkey: 'profileHistory',
  },
  {
    label: 'Můj účet',
    href: '/profile/account',
    key: 'profile',
    icon: 'i-fubex-settings',
    trkey: 'profile',
  },
  {
    label: 'Bonusový program',
    key: 'profile-bonus',
    href: '/profile/bonus',
    icon: 'i-fubex-bonus',
    trkey: 'profileBonus',
  },
  {
    label: 'Odhlásit se',
    key: 'logout',
    href: '/logout',
    icon: 'i-fubex-logout',
    trkey: 'logout',
  },
]
const mobileMenuOpen = ref(false)

export default function useNavigation () {
  const getNavigation = () => new Promise(resolve => resolve(navigation))

  return {
    mobileMenuOpen,
    navigation,
    profileNavigation,
    getNavigation,
  }
}
